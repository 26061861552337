.verificationInput {
  width: 32px;
  height: 40px;
  margin: 8px;
  margin-left: 0;
  border: 1px solid #828f9b;
  border-radius: 4px;
  text-align: center;
}

.error {
  border-color: #db433b;
}

.errorRow {
  text-align: left;
  color: #db433b;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 13px;
}

.inputRow {
  display: flex;
  flex-direction: row;
}

.resendRow {
  font-size: 14px;
  color: #db433b;
}

.linkButton {
  border-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
  border-width: 0;
  font-size: 16px;
  color: #1e7d95;
  margin-bottom: 8px;
  cursor: pointer;
}

.disableLinkButton {
  composes: linkButton;
  color: #76c0d0;
  margin-bottom: 0;
  cursor: not-allowed;
}

.countDown {
  padding-top: 8px;
  color: #101419;
  font-size: 16px;
}

.btnRow {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.btn {
  margin-top: 0px;
}

.inputBlock {
  display: flex;
  flex-direction: column;
}

.label {
  color: #2e4461;
  font-size: 14px;
  padding-bottom: 8px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 10px;
}

.loading {
  color: #5D7487;
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .btnRow {
    display: flex;
    flex-direction: column;
    max-width: 343px;
  }

  .btn {
    width: 100%;
  }

  .btn:first-of-type {
    margin-top: 32px;
  }
}
