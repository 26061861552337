.wrapper {
  display: flex;
  color: rgba(16, 20, 25, 0.5);
  margin-bottom: 16px;
}

.crumbWrapper {
  display: flex;
}

.crumb {
  padding-inline: 6px;
}

.crumb:first-child {
  padding-left: 0;
}
