.pricing {
  display: flex;
  font-size: 20px;
  font-weight: medium;
  margin-bottom: 16px;
}

.priceUsd {
  margin-right: 8px;
}

.priceEth {
  color: #10141980;
  border-left: 2px solid #e6eaef;
  padding-left: 8px;
}
