.card {
  --primaryColor: var(--primaryColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 16px;
  background-color: #ffffff;
  color: inherit;
  border-top: 8px solid var(--primaryColor);
  border-radius: 8px;
  box-shadow: 0px 4px 5px #0000001a;
  min-width: 300px;
}

.cardError {
  composes: card;
  position: relative;
}

.cardError:after {
  content: '';
  position: absolute;
  top: -8px;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #db433b;
  border-radius: 8px;
  pointer-events: none;
}

.dataErrorIcon {
  width: 28px;
  height: 28px;
  fill: #ac2720;
  margin-bottom: 4px;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.cardHeader h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}

.cardData {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.amount {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  color: var(--dynamicColor);
}

.highlight {
  color: var(--primaryColor);
}

.units {
  font-size: 12px;
  line-height: 1.45;
  font-weight: 600;
}

.cardFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.footerButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  color: inherit;
  border: none;
  cursor: pointer;
}

.footerText {
  color: inherit;
  text-align: right;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.25;
  text-transform: uppercase;
  margin-right: 14px;
}

.footerIcon {
  width: 6px;
}

/* Tooltip */

.tooltip {
  display: inline;
  isolation: isolate;
  z-index: 1;
}

.tooltipIcon {
  fill: #2e4461;
  width: 16px;
  height: 16px;
  height: auto;
  cursor: pointer;
}

.tooltipBox {
  display: none;
  position: absolute;
  top: 12px;
  right: 8px;
  transform: translateY(-100%);
  font-size: 10px;
  font-weight: 500;
  line-height: 2;
  padding: 16px;
  border-radius: 4px;
  max-width: 160px;
  color: #fff;
  background: #2e4461;
  box-shadow: 0px 4px 5px #0000001a;
}

.tooltipBox:after {
  content: '';
  position: absolute;
  left: calc(100% - 18px);
  top: 100%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-right: 11px solid transparent;
  border-top: 7px solid #2e4461;
  border-left: 11px solid transparent;
}

.tooltip:hover .tooltipBox {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 1250px) {
  .card {
    min-width: 250px;
  }
}

@media (max-width: 767px) {
  .card {
    width: 100%;
    min-width: 320px;
  }
}
